.about {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.aboutTop {
  width: 100%;
  height: 45%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: sans-serif;
}

.aboutBottom {
  display: flex;
  flex-direction: column;
  height: 55%;
  align-items: center;
  margin-top: 50px;
  font-family: sans-serif;
}

.aboutBottom p {
  font-size: 30px;
  font-size: 2.5vw;
  margin-right: 2px;
  margin-left: 2px;
  font-family: sans-serif;

}

.label {
  padding-bottom: 2px;
  margin-bottom: 2px;
}
.about .aboutBottom h1 {
  font-weight: 400;
  font-size: 50px;
  font-size: 4.5vw;
  margin-bottom: 10px;
  color: black;
  height: 30px;
  display: flex;
  font-family: sans-serif;
  justify-content: center;
  transform: translateY(-40px);
}
